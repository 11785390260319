<template>
  <span v-if="error || valid" class="badge flex justify-center items-center w-6 h-6 rounded-large">
    <Icon :icon="error ? 'times' : 'check'" class="h-icon" />
  </span>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'Badge',
  components: {
    Icon
  },
  props: {
    error: {
      type: Boolean,
      required: true
    },
    valid: {
      type: Boolean,
      required: true
    }
  }
}
</script>
