<template>
  <label class="relative">
    <input
      v-model="value"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      class="h-10 w-full pt-4 px-5 border rounded placeholder-transparent focus:placeholder-primary focus:border-active peer disabled:text-input-disabled"
      :class="{
        'border-valid': isValid,
        'border-error': hasError
      }"
      @blur="$emit('blur')"
    >
    <span
      class="absolute top-0 h-10 ml-5 flex pointer-events-none text-label peer-focus:text-sm peer-focus:text-label peer-focus:items-start peer-focus:mt-3 peer-disabled:text-input-disabled"
      :class="{
        'text-label-error': hasError,
        'text-label': !hasError,
        'text-sm items-start mt-3': hasInput,
        'items-center': !hasInput
      }"
    >
      {{ prompt }}
    </span>
    <span
      v-if="errorAdditional"
      class="absolute bottom-full z-10 mb-3 ml-4 mr-9 p-3 flex text-sm text-white bg-tooltip rounded peer-focus:hidden after:absolute after:top-full after:ml-6 after:border-8 after:border-t-tooltip after:border-r-transparent after:border-b-transparent after:border-l-transparent"
    >
      {{ errorAdditional }}
    </span>
    <Badge
      :error="hasError"
      :valid="isValid"
      class="absolute top-16 right-20 text-white peer-focus:hidden"
      :class="{
        'bg-badge-valid': isValid,
        'bg-badge-error': hasError
      }"
    />
  </label>
</template>

<script>
import Badge from '@/components/Badge'

export default {
  name: 'BaseInput',
  components: {
    Badge
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text',
      validator: val => ['text', 'email', 'password'].includes(val)
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasInput: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    errorAdditional: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    hasError () {
      return this.error.length > 0
    },
    prompt () {
      return this.hasError ? this.error : this.label
    }
  }
}
</script>
