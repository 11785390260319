<template>
  <div class="flex items-center justify-center h-auto w-full inline-block px-7 mb-7">
    <svg class="max-w-reg-success max-h-full" viewBox="0 0 274 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="134.41" cy="128" r="128" fill="white"/>
      <g clip-path="url(#clip0_528:4963)">
      <path d="M244.331 215.488L239.49 221.782L229.379 210.839L233.986 204.889L244.331 215.488Z" fill="#CAD7E0"/>
      <path d="M218.637 92.7003L203.078 89.4688H197.016L193.986 141.376L186.711 203.18L203.078 201.362L204.897 196.515L217.223 154.302L234.196 92.7003H218.637Z" fill="white"/>
      <path d="M230.963 70.2812L234.632 81.7735C234.632 81.7735 236.653 87.0249 236.249 92.6801C235.845 98.3354 204.727 91.4683 204.727 91.4683L207.152 77.7341L230.963 70.2812Z" fill="#CAD7E0"/>
      <path d="M134.733 80.8845C134.733 80.8845 114.203 86.2247 122.286 80.8845C130.368 75.5443 125.115 61.9879 125.115 61.9879C125.115 45.83 136.875 35.5253 136.875 35.5253C136.875 35.5253 140.067 32.0958 136.834 43.8103C136.834 43.8103 137.081 52.9556 133.306 59.1603C131.581 61.9879 136.026 72.4905 136.026 72.4905L134.733 80.8845Z" fill="#CAD7E0"/>
      <path d="M31.1963 40.9915L18.1067 64.3477C18.1067 64.3477 24.0837 70.8755 38.0987 79.3584C52.1137 87.8413 55.2013 81.7821 53.6898 79.3584C52.1784 76.9347 53.1806 71.2794 53.1806 71.2794C53.1806 71.2794 59.6466 69.2597 57.9735 68.0479C56.3005 66.836 51.5641 63.2005 51.5641 53.5058C51.5641 43.811 39.7677 40.9834 39.7677 40.9834L31.1963 40.9915Z" fill="#CAD7E0"/>
      <path d="M235.845 88.2451L251.404 94.5063L266.962 100.767C266.962 100.767 291.614 169.842 244.331 219.528L229.379 205.39C229.379 205.39 254.03 175.902 248.777 142.778" fill="#131947"/>
      <path d="M232.781 101.184L244.501 103.809L240.258 91.6909L233.792 88.8633L232.781 101.184Z" fill="#131947"/>
      <path d="M212.208 145.82C212.208 145.82 176.439 219.944 223.317 207.826C223.317 207.826 247.969 209.846 254.03 143.194C260.092 76.543 223.317 104.415 223.317 104.415L212.208 145.82Z" fill="#131947"/>
      <path d="M203.111 83.8017C203.111 83.8017 213.618 91.004 223.317 80.9417C233.016 70.8794 230.187 68.0477 235.037 64.0082C239.886 59.9688 239.482 51.8898 239.482 51.8898L221.3 42.4092L203.115 46.691C203.115 46.691 199.882 49.0703 200.69 58.3611C200.69 58.3611 197.603 63.2731 200.561 66.6743C200.557 66.6662 198.665 81.7819 203.111 83.8017Z" fill="#CAD7E0"/>
      <path d="M218.468 36.1355C218.468 36.1355 188.562 43.8105 205.131 48.6578C221.7 53.5052 222.105 46.2341 221.7 53.5052C221.296 60.7762 221.785 59.528 221.785 59.528C221.785 59.528 226.55 60.3723 225.338 66.4315H227.201C227.201 66.4315 231.444 59.0029 236.475 62.5173C241.507 66.0316 247.16 30.4802 218.468 36.1355Z" fill="#131947"/>
      <path d="M203.519 86.2982C203.519 86.2982 195.267 77.7587 184.121 82.5899L177.578 88.2452C177.578 88.2452 169.976 87.8413 165.531 91.4768C165.531 91.4768 155.881 90.6689 152.825 91.4768C149.77 92.2847 138.051 75.3189 134.818 68.0479L118.653 84.6097C118.653 84.6097 129.16 105.211 143.304 108.443C157.449 111.674 178.867 112.886 178.059 121.773C177.251 130.66 182.1 143.99 177.655 155.704C173.209 167.419 174.248 177.481 174.248 177.481C174.248 177.481 172.805 205.39 170.381 207.41C167.956 209.429 189.779 206.198 190.587 202.158C191.395 198.119 204.089 191.466 199.154 144.701C199.154 144.701 198.556 116.166 203.519 99.2608C206.748 88.2452 203.519 86.2982 203.519 86.2982Z" fill="#131947"/>
      <path d="M236.305 86.6414V105.683L229.379 106.637L236.305 112.349L209.868 151.022C209.868 151.022 219.68 113.29 225.742 97.5358C231.803 81.7819 234.923 87.3523 232.555 75.2783L236.305 86.6414Z" fill="#131947"/>
      <path d="M110.203 47.0118C108.182 44.5881 111.819 42.9723 114.648 47.0118C114.648 47.0118 116.265 42.3341 114.648 25.0613C114.244 20.7552 118.827 25.7763 118.827 25.7763C118.827 25.7763 118.689 18.3315 122.326 22.371C125.964 26.4105 125.155 36.5091 125.155 36.5091C125.155 36.5091 126.772 29.2381 125.155 23.9868C125.155 23.9868 128.792 20.7552 129.601 29.2381C130.409 37.721 130.409 39.7407 130.409 39.7407C130.409 39.7407 136.471 49.8394 127.176 60.746C127.176 60.746 112.223 49.4354 110.203 47.0118Z" fill="#EAF1F9"/>
      <path d="M118.649 59.1607C118.649 59.1607 137.077 58.171 130.692 80.8849C130.692 80.8849 118.649 96.7277 100.867 102.787C83.0858 108.846 68.5858 108.967 66.541 113.148C64.4961 117.329 62.4755 124.196 81.4693 162.975C100.463 201.754 74.575 215.084 74.575 215.084L44.8529 212.224L14.0614 221.924C14.0614 221.924 17.3875 219.495 16.9025 212.224C16.4176 204.953 11.9599 177.921 13.9805 173.882C16.0012 169.842 -0.511355 145.201 0.672729 128.236C1.85681 111.27 1.45269 91.0725 26.5084 80.5699C26.5084 80.5699 51.16 91.0725 71.3662 88.2448C91.5724 85.4172 118.649 59.1607 118.649 59.1607Z" fill="#131947"/>
      <path d="M12.364 79.3578L31.7337 75.2779C31.7337 75.2779 25.6435 55.9168 31.7337 54.713C37.8238 53.5092 35.4839 60.7722 35.4839 60.7722L40.3011 54.5757H45.1223V44.5416C45.1223 44.5416 54.8213 31.1508 28.7431 35.0569C2.665 38.9631 6.5648 59.1605 6.5648 59.1605C6.5648 59.1605 16.0011 74.5104 12.364 79.3578Z" fill="#131947"/>
      </g>
      <defs>
      <clipPath id="clip0_528:4963">
      <rect width="273.18" height="213.393" fill="white" transform="translate(0.410004 21)"/>
      </clipPath>
      </defs>
    </svg>
  </div>
</template>
