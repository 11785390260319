<template>
  <label
    class="border rounded px-4 py-5 flex items-center focus-within:bg-option-active "
    :class="{
      'border-option-valid bg-option-valid': isValid,
      'border-option-error': hasError
    }"
  >
    <span
      class="relative flex-none"
    >
      <input
        v-model="checked"
        type="checkbox"
        :name="name"
        :disabled="disabled"
        class="appearance-none rounded-none border border-option h-6 w-6 flex items-stretch justify-stretch disabled:border-disabled"
        @blur="$emit('blur')"
      >
      <span
        v-show="checked"
        class="pointer-events-none inset-3 absolute flex justify-center items-center text-sm text-white bg-option-checked"
      >
        <Icon icon="check" />
      </span>
    </span>
    <div class="ml-3 flex flex-col">
      <span v-if="hasError" class="text-label-error text-sm">
        {{ error }}
      </span>
      <span :class="{ 'text-input-disabled': disabled}">
        {{ label }}
        <a
          v-if="labelLinkText && labelLinkUrl"
          :href="labelLinkUrl"
          class="underline"
          target="_blank"
        >
          {{ labelLinkText }}
        </a>
      </span>
    </div>
  </label>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'BaseCheckbox',
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: [Boolean],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    labelLinkText: {
      type: String,
      default: ''
    },
    labelLinkUrl: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    checked: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    hasError () {
      return this.error.length > 0
    }
  }
}
</script>
