import { createApp } from 'vue'
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'

createApp(App)
  .use(router)
  .use(VueBrowserUpdate, { options: {}, test: false })
  .mount('#app')
