<template>
  <div class="logo">
    <svg viewBox="0 0 268 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1649 23.9564V28.7573H13.7299V53.295H8.38104V28.7573H-0.0166016V23.9564H22.1649Z"/>
      <path d="M40.3782 42.2687C40.3782 46.4081 39.5866 49.3402 38.0033 51.065C36.4201 52.7897 33.7172 53.6539 29.8945 53.6574C26.0184 53.6574 23.2941 52.8075 21.7215 51.1077C20.149 49.4078 19.3645 46.4615 19.3681 42.2687C19.3681 38.1685 20.1668 35.2524 21.7643 33.5205C23.3618 31.7886 26.0719 30.9245 29.8945 30.9281C33.7457 30.9281 36.4557 31.778 38.0247 33.4778C39.5937 35.1777 40.3782 38.108 40.3782 42.2687ZM24.519 42.2261C24.519 44.9466 24.8595 46.7335 25.5406 47.587C26.2217 48.4405 27.673 48.869 29.8945 48.8726C32.0555 48.8726 33.4818 48.437 34.1736 47.5657C34.8689 46.6909 35.2433 44.8985 35.2433 42.2314C35.2433 39.5643 34.9064 37.6866 34.2431 36.8278C33.5799 35.969 32.1036 35.5422 29.9106 35.5422C27.7176 35.5422 26.2734 35.985 25.578 36.8704C24.8827 37.7559 24.519 39.5483 24.519 42.2367V42.2261Z"/>
      <path d="M49.0327 60.0052H43.8764V31.3815H48.3641L48.6743 33.3765H48.899C49.7487 32.5667 50.7629 31.9482 51.8729 31.5629C53.1301 31.1303 54.4532 30.9191 55.7829 30.9388C58.739 30.9388 60.8482 31.8278 62.1106 33.6059C63.3729 35.384 64.0023 38.2716 63.9987 42.2688C63.9987 46.2837 63.2142 49.1856 61.6452 50.9743C60.0762 52.7631 57.4999 53.6521 53.9162 53.6415C52.9943 53.6459 52.0733 53.5799 51.1615 53.4441C50.431 53.3445 49.7136 53.1657 49.022 52.9107L49.0327 60.0052ZM58.8478 42.2688C58.8478 39.7012 58.5144 37.9373 57.8476 36.9772C57.1843 36.017 55.8845 35.5369 53.959 35.5369C53.076 35.5347 52.196 35.6386 51.338 35.8463C50.5354 36.0439 49.7609 36.3414 49.0327 36.7318V48.1631C49.6205 48.4191 50.2389 48.5984 50.8727 48.6966C51.6599 48.8208 52.456 48.8797 53.2529 48.8726C55.5315 48.8726 57.0328 48.4228 57.7566 47.523C58.4805 46.6233 58.8442 44.8719 58.8478 42.2688Z"/>
      <path d="M81.9492 23.8066H87.1002V53.2679H82.5697L82.2595 51.2302H82.0348C81.2031 52.0587 80.1935 52.688 79.0823 53.0705C77.815 53.4894 76.4857 53.6913 75.1509 53.668C72.2197 53.668 70.1248 52.7789 68.866 51.0008C67.6073 49.2227 66.9779 46.312 66.9779 42.2686C66.9779 38.2821 67.7552 35.3963 69.31 33.6111C70.8647 31.8259 73.4339 30.9368 77.0176 30.9439C77.913 30.9418 78.8072 31.0095 79.692 31.1466C80.4559 31.2539 81.2081 31.4325 81.9385 31.68L81.9492 23.8066ZM77.7237 35.5421C75.4451 35.5421 73.9438 36.0008 73.22 36.9183C72.4961 37.8358 72.1395 39.6139 72.1502 42.2526C72.1502 44.8237 72.4658 46.568 73.1076 47.4802C73.7495 48.3924 75.0546 48.8564 77.039 48.8564C77.9386 48.8627 78.8359 48.766 79.7134 48.5684C80.5067 48.3893 81.2704 48.0983 81.9813 47.7042V36.2995C81.3535 36.0322 80.6965 35.8389 80.0237 35.7234C79.2635 35.5988 78.494 35.5399 77.7237 35.5474V35.5421Z"/>
      <path d="M97.1132 53.6681C94.8667 53.6681 93.2157 53.1133 92.1602 52.0038C91.1047 50.8942 90.5787 49.1606 90.5823 46.8028C90.5823 44.3811 91.2117 42.667 92.4704 41.6606C93.7292 40.6542 95.8919 40.1457 98.9585 40.135C99.8339 40.1349 100.709 40.1794 101.579 40.2684C102.356 40.3392 103.128 40.4567 103.89 40.6204V38.7588C103.93 38.2832 103.854 37.8051 103.667 37.3656C103.481 36.926 103.19 36.5383 102.82 36.2356C102.109 35.7022 100.98 35.4355 99.4453 35.4355C98.0492 35.4355 96.8137 35.4835 95.7332 35.5689C94.6964 35.6543 93.6658 35.8021 92.6469 36.0116V31.7175C93.8537 31.4382 95.0777 31.2387 96.3109 31.1201C97.6267 30.9885 99.1885 30.9209 100.996 30.9174C103.692 30.9174 105.707 31.5308 107.041 32.7577C108.374 33.9846 109.041 35.8231 109.041 38.2733V53.2467H104.286L104.067 51.337H103.976C102.982 52.0696 101.882 52.6454 100.713 53.044C99.5565 53.4513 98.3397 53.6623 97.1132 53.6681ZM99.6057 43.8316C98.0011 43.8316 96.9046 44.0095 96.3162 44.3651C95.7225 44.7331 95.4283 45.4319 95.4283 46.4721C95.4283 47.5123 95.669 48.1898 96.1611 48.6058C96.6532 49.0219 97.4823 49.2513 98.6697 49.2513C99.5932 49.2414 100.512 49.1142 101.403 48.8726C102.274 48.6547 103.116 48.3342 103.912 47.9177V44.1891C103.2 44.0664 102.494 43.981 101.804 43.9223C101.114 43.8637 100.349 43.8316 99.5843 43.8316H99.6057Z"/>
      <path d="M117.883 53.2682H112.727V31.3977H117.171L117.391 33.6167H117.524C118.502 32.7355 119.64 32.0508 120.878 31.6004C122.244 31.1429 123.679 30.9246 125.12 30.9549C127.548 30.9549 129.399 31.5613 130.672 32.7739C131.945 33.9866 132.581 35.7736 132.581 38.1349V53.2842H127.43V38.7803C127.461 38.3248 127.382 37.8686 127.2 37.4494C127.019 37.0301 126.74 36.6599 126.387 36.3692C125.485 35.7642 124.407 35.4753 123.323 35.5477C122.361 35.5464 121.404 35.6738 120.477 35.9265C119.567 36.1724 118.7 36.5537 117.904 37.0574L117.883 53.2682Z"/>
      <path d="M141.417 53.2683H136.266V31.3977H141.016L141.241 33.0407H141.375C142.412 32.3874 143.523 31.8592 144.685 31.4671C145.679 31.1271 146.721 30.947 147.772 30.9336C148.84 30.9018 149.904 31.0832 150.901 31.4671C151.756 31.8196 152.49 32.4127 153.014 33.174H153.147C154.256 32.4589 155.459 31.899 156.72 31.5097C157.949 31.1227 159.229 30.9213 160.518 30.9123C162.657 30.9123 164.262 31.4884 165.332 32.6406C166.402 33.7928 166.937 35.5069 166.937 37.7828V53.2523H161.786V38.471C161.82 38.0642 161.765 37.6548 161.622 37.2721C161.48 36.8894 161.254 36.5428 160.962 36.2572C160.206 35.7227 159.286 35.4714 158.362 35.5478C157.63 35.5513 156.902 35.6408 156.191 35.8145C155.485 35.9798 154.799 36.2175 154.142 36.524C154.176 36.6836 154.192 36.8463 154.19 37.0094V53.2683H148.997V38.471C149.031 38.0647 148.976 37.6559 148.834 37.2734C148.693 36.8909 148.469 36.544 148.178 36.2572C147.422 35.7244 146.502 35.4733 145.579 35.5478C144.912 35.5524 144.247 35.6347 143.6 35.7932C142.843 35.9765 142.106 36.2356 141.401 36.5666L141.417 53.2683Z"/>
      <path d="M176.778 53.6681C174.528 53.6681 172.877 53.1133 171.825 52.0038C170.774 50.8942 170.248 49.1606 170.248 46.8028C170.248 44.3811 170.877 42.667 172.136 41.6606C173.394 40.6542 175.564 40.1457 178.645 40.135C179.521 40.135 180.395 40.1795 181.266 40.2684C182.041 40.3387 182.811 40.4563 183.571 40.6204V38.7588C183.612 38.2832 183.535 37.8051 183.349 37.3656C183.162 36.926 182.872 36.5383 182.502 36.2356C181.789 35.7022 180.663 35.4355 179.127 35.4355C177.736 35.4355 176.495 35.4835 175.42 35.5689C174.381 35.6543 173.349 35.8021 172.328 36.0116V31.7175C173.537 31.4383 174.763 31.2387 175.998 31.1201C177.313 30.9885 178.875 30.9209 180.683 30.9174C183.379 30.9174 185.392 31.5308 186.722 32.7577C188.052 33.9846 188.719 35.8231 188.722 38.2733V53.2467H183.967L183.764 51.337H183.673C182.679 52.0685 181.579 52.6442 180.41 53.044C179.244 53.4544 178.016 53.6655 176.778 53.6681ZM179.266 43.8316C177.668 43.8316 176.572 44.0095 175.976 44.3651C175.382 44.7331 175.088 45.4319 175.088 46.4721C175.051 46.8593 175.097 47.25 175.223 47.6179C175.35 47.9859 175.554 48.3227 175.821 48.6058C176.313 49.0326 177.148 49.2513 178.335 49.2513C179.257 49.2414 180.173 49.1142 181.063 48.8726C181.935 48.6566 182.777 48.336 183.571 47.9177V44.1891C182.865 44.0664 182.159 43.981 181.464 43.9223C180.769 43.8637 180.02 43.8316 179.266 43.8316Z"/>
      <path d="M197.618 53.268H192.461V31.3974H197.216L197.441 33.1737H197.575C198.623 32.5424 199.741 32.0357 200.907 31.6641C201.968 31.3203 203.076 31.1404 204.191 31.1307H204.79C204.875 31.1254 204.959 31.14 205.036 31.1734V36.1343C204.812 36.0675 204.58 36.0299 204.346 36.0222C204.063 36.0222 203.587 36.0222 202.902 36.0222C201.933 36.017 200.966 36.1063 200.014 36.289C199.188 36.4455 198.384 36.6979 197.618 37.0411V53.268Z"/>
      <path d="M213.343 44.2743V53.2679H208.192V23.8066H213.343V40.1349H215.21L221.783 31.4027H227.913L219.515 41.9912L228.357 53.2892H222.003L215.252 44.2956L213.343 44.2743Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M247.287 24.1321C247.287 24.1321 251.063 24.5322 253.705 26.8793C256.348 29.2264 257.022 29.6851 258.76 29.9518C260.498 30.2185 261.482 30.6079 260.825 31.5521C260.167 32.4963 258.327 34.4966 252.951 33.2751C247.575 32.0535 246.944 28.1702 246.832 27.338C246.72 26.5059 246.436 23.9721 247.287 24.1321Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M243.617 16.9951C243.617 16.9951 240.018 17.3471 238.803 16.4617C237.589 15.5762 238.472 14.2799 236.808 12.6156C235.145 10.9513 232.781 9.73513 232.636 7.48939C232.523 5.35975 232.625 3.22415 232.941 1.11493C233.144 0.325453 234.546 -0.0692837 235.696 0.810873C236.846 1.69103 242.831 5.23299 243.238 6.4012C243.73 7.79878 246.142 7.31336 245.73 9.25504C245.377 11.0154 242.553 12.9304 243.617 16.9951Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M245.767 15.624C245.767 15.624 245.543 10.9512 246.896 9.90035C248.249 8.84949 251.063 5.39821 252.641 3.70724C254.219 2.01628 256.732 -0.293466 258.733 0.0319247C260.733 0.357316 261.407 0.608029 262.033 1.25881C262.659 1.90959 263.344 2.37367 261.429 2.75241C259.514 3.13114 257.417 3.13648 256.272 4.66208C255.128 6.18769 252.448 7.7933 251.539 11.1806C250.63 14.5678 249.795 17.4804 248.597 17.3523C248.597 17.3523 245.757 16.4508 245.767 15.624Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M251.015 15.6242C252.651 13.9332 251.164 14.664 254.909 13.3304C258.653 11.9969 260.749 9.16971 260.979 8.23088C261.209 7.29204 262.408 4.69425 263.921 4.49688C265.435 4.29951 268.174 5.26501 267.623 8.09218C267.225 9.78186 266.431 11.3533 265.306 12.6775C264.181 14.0017 262.757 15.0408 261.151 15.7095C257.61 17.1178 255.577 17.8753 253.127 17.8433C250.678 17.8112 249.378 17.3205 251.015 15.6242Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M250.309 21.7638C249.993 21.433 249.827 20.8036 250.843 20.0835C252.48 18.9366 255.657 17.5977 260.418 19.4167C265.178 21.2357 268.553 22.5212 267.147 23.8868C263.986 26.9487 264.012 28.0582 265.724 30.2879C267.329 32.3683 264.007 33.0351 264.007 33.0351C264.007 33.0351 262.285 33.4352 261.616 31.0507C260.947 28.6663 260.546 25.6204 258.979 24.783C254.951 22.6226 250.849 22.3345 250.309 21.7638Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M241.804 25.1193C241.29 24.9546 240.739 24.9432 240.218 25.0862C239.697 25.2293 239.23 25.5207 238.873 25.9247C237.846 26.9916 234.059 30.6242 233.524 35.8465C232.989 41.0688 235.428 40.4126 236.61 40.3433C237.792 40.2739 238.557 40.3433 238.873 41.0367C239.188 41.7302 240.633 41.4955 241.488 40.418C242.819 38.8595 244.248 37.3878 245.768 36.0119C246.934 34.9823 247.554 31.9311 246.907 30.2935C246.372 28.9493 245.944 28.7306 245.837 28.6932C245.81 28.6932 245.8 28.6932 245.794 28.6932C245.789 28.6932 243.847 29.056 243.008 31.6271C242.168 34.1982 240.542 36.6786 239.873 36.7747C239.205 36.8707 237.006 37.6335 237.578 35.9158C238.151 34.1982 240.756 32.1818 241.82 30.8483C242.884 29.5147 243.826 28.2398 243.735 26.7409C243.644 25.2419 241.804 25.1193 241.804 25.1193Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M232.331 24.831C230.593 24.2496 228.357 23.5081 227.517 22.5159C226.678 21.5238 225.084 16.9256 226.389 16.3015C227.694 15.6774 227.892 14.9146 229.598 15.6454C231.304 16.3762 232.273 16.0348 234.102 16.3015C235.877 16.5583 237.602 17.0873 239.215 17.8698L240.103 18.4032C240.103 18.4032 241.9 19.5714 240.103 19.8755C238.306 20.1795 236.744 19.6888 235.471 20.0782C234.198 20.4676 232.765 21.4224 234.487 21.6144C236.209 21.8065 237.814 20.9957 238.477 21.5184C239.14 22.0412 240.387 22.5853 238.873 23.6521C237.151 24.9484 234.043 25.4178 232.331 24.831Z" fill="#62ADDA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M242.066 19.7422C241.66 20.2756 241.087 21.2038 241.125 21.8759C241.267 22.6614 241.639 23.3872 242.194 23.9616C242.78 24.2573 243.447 24.3547 244.093 24.239C244.777 24.0553 245.439 23.7977 246.067 23.4708C246.708 22.9909 247.283 22.4296 247.779 21.8012C248.243 21.1416 248.522 20.3698 248.586 19.5661C248.533 19.0327 248.586 18.2752 247.875 17.8058C247.289 17.4478 246.624 17.239 245.939 17.1977C243.735 17.107 242.36 19.3741 242.066 19.7422Z" fill="#62ADDA"/>
    </svg>
  </div>
</template>
