<template>
  <svg
    class="icon inline-flex"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    v-bind="svgAtttributes"
  >
    <template v-if="icon === 'times'">
      <path d="M0.666504 7.8665L7.8665 0.666504" stroke="currentColor"/>
      <path d="M7.8667 7.8665L0.666698 0.666504" stroke="currentColor"/>
    </template>
    <template v-else-if="icon === 'check'">
      <path d="M0.600098 3.9999L4.2001 7.5999L11.4001 0.399902" stroke="currentColor"/>
    </template>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      validator (value) {
        // The value must match one of these strings
        return ['check', 'times'].includes(value)
      }
    }
  },
  data () {
    return {
      dimensions: {
        check: {
          width: '12',
          height: '9'
        },
        times: {
          width: '9',
          height: '9'
        }
      }
    }
  },
  computed: {
    svgAtttributes () {
      const dimensions = this.dimensions[this.icon]
      return {
        width: dimensions.width,
        height: dimensions.height,
        viewBox: `0 0 ${dimensions.width} ${dimensions.height}`
      }
    }
  }
}
</script>
