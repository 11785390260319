<template>
  <div class="flex min-h-screen relative flex-col md:flex-row md:flex-wrap md:items-stretch">
    <div class="flex flex-col items-start w-full px-5 pt-8 pb-7 md:w-1/2 md:p-11 md:bg-primary md:text-white">
      <TopdanmarkLogo class="hidden md:block" />
      <div class="site-name md:pt-main-logo md:w-full md:flex md:justify-center">
        Hackingalarm
      </div>
    </div>
    <div class="flex px-5 pb-7 w-full flex-grow md:w-1/2 md:pt-12 md:px-11 xl:pl-12">
      <div class="flex flex-col items-center w-full">
        <router-view class="flex flex-col flex-grow w-full xl:max-w-form" />
        <div class="flex items-center divide-x divide-primary pt-10 md:pt-11">
          <SkimSafeLogo class="small-logo pt-2 pb-3 pr-5" />
          <TopdanmarkLogo class="small-logo pt-2 pb-3 pl-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopdanmarkLogo from '@/components/TopdanmarkLogo'
import SkimSafeLogo from '@/components/SkimSafeLogo'

export default {
  name: 'App',
  components: {
    TopdanmarkLogo,
    SkimSafeLogo
  },
  beforeCreate () {
    const html = document.documentElement
    html.setAttribute('lang', 'da')
  }
}
</script>
